import React from 'react';
import classNames from 'classnames';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';

const tooltipWrapperClassNames =
    'max-width-250 bg-white text-color-black display-flex flex-column align-items-start margin-10';
const tooltipSpacerClassNames =
    'margin-0 margin-bottom-5 border border-color-blue border-top-only border-width-5 width-100pct';

export const renderTableHeader = (
    column: React.Key,
    label: string | undefined,
    handleSortChange: (event: any) => void,
    sortBy: string,
    sortDir: string,
) => {
    return (
        <th
            key={column}
            className={classNames('user-select-none', 'sort-column')}
            onClick={handleSortChange}
            data-field={column}
            data-sortby={column}
            title={label}
        >
            <span>
                {sortBy === column ? <SortArrows direction={sortDir} /> : <SortArrows />}
                <span>{label}</span>
                {column === 'disch_energy_thru' && (
                    <OverlayTrigger
                        placement={'left'}
                        overlay={
                            <Tooltip id={'dischargedTooltip'} textAlignment={'left'}>
                                <div className={tooltipWrapperClassNames}>
                                    <h6>
                                        <FormattedMessage id={'batteryService.dischargedEnergyThroughput'} />
                                    </h6>
                                    <hr className={tooltipSpacerClassNames} />
                                    <span>
                                        <FormattedMessage
                                            id={'batteryService.dischargedEnergyThroughput.tooltip.body'}
                                        />
                                    </span>
                                </div>
                            </Tooltip>
                        }
                    >
                        <span className="rioglyph rioglyph-info-sign margin-left-5 text-color-dark" />
                    </OverlayTrigger>
                )}
            </span>
        </th>
    );
};
