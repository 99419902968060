import { config } from '../../config';
import { trace } from './trace';
import { routeStorage } from '../login/storage';
import { reportErrorToSentry } from './sentry';
import { UserManager } from 'oidc-client-ts';

const param = (window: Window, regex: RegExp, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (substring, it) => {
        result = it;
        return substring;
    });
    return result;
};

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#/u, '');
    routeStorage.saveRoute(initialRoute);
    trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = Boolean(param(window, /.*code=([^&]+)/u));

    try {
        const user = await userManager.signinSilent();

        return Promise.resolve(user);
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);
        const needToSigninError = new Error('Need to sign in');

        if (config.login.preventRedirect) {
            // eslint-disable-next-line no-console
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
            routeStorage.discardRoute();
            reportErrorToSentry(error);
            return Promise.reject(needToSigninError);
        } else {
            saveCurrentRoute();
            try {
                // Code after userManager.signinRedirect() will still be executed until
                // the current page is completely unloaded which happens asynchronously.
                // Hence, you should structure the code such that nothing relevant is executed afterwards.
                await userManager.signinRedirect();
                return Promise.resolve();
            } catch (e) {
                console.error('SigninRedirect Failed when attemptInitialSignIn', e);
                return Promise.reject(new Error('SigninRedirect Failed when attemptInitialSignIn'));
            }
        }
    }
};
