import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from '../features/app/App';
import { ErrorBoundary } from '../features/app/ErrorBoundary';
import AssetBatteryData from '../pages/AssetBatteryData';
import React from 'react';
import AssetsOverview from '../pages/AssetsOverview';
import BatteryHistoryGraph from '../features/batteryHistoryGraph/BatteryHistoryGraph';

export const ASSET_ID_ROUTE = '/:assetId';
export const DIALOG_ASSET_ID_ROUTE = '/dialog/:assetId';
export const OVERVIEW_ROUTE = '/overview';
export const DEFAULT_ROUTE = '/error';
export const routes = [ASSET_ID_ROUTE, OVERVIEW_ROUTE, DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path={OVERVIEW_ROUTE} errorElement={<ErrorBoundary />} element={<AssetsOverview />} />
            <Route path={ASSET_ID_ROUTE} errorElement={<ErrorBoundary />} element={<AssetBatteryData />} />
            <Route path={DIALOG_ASSET_ID_ROUTE} errorElement={<ErrorBoundary />} element={<BatteryHistoryGraph />} />
        </Route>,
    ),
);
