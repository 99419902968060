import { Suspense } from 'react';
import { LoadingIndicator } from '../features/app/LoadingIndicator';
import { isWorkshopParentFrontend } from '../config';
import DiagnosticsTable from '../features/batteryOverview/customer/DiagnosticsTable';

const AssetsOverview = () => {
    const isWorkshopParentFE = isWorkshopParentFrontend();

    return (
        <div className={'overflow-hidden display-flex flex-column'}>
            <Suspense fallback={<LoadingIndicator />}>{!isWorkshopParentFE && <DiagnosticsTable />}</Suspense>
        </div>
    );
};

export default AssetsOverview;
