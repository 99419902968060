import React, { useEffect } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { RootState, store } from '../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import {
    assetsChanged,
    criticalityChanged,
    currentFiltersSelected,
    detailClosed,
    viewTypeChanged,
} from '../batteryOverview/slices/batteryOverviewSlice';
import { accessToken } from '../../configuration/tokenHandling/accessToken';
import { featureToggleChanged } from '../../configuration/featureToggle/featureToggleSlice';
import { isReadinessNotifiedSelector, readinessNotified } from './appSlice';

// Events sent
export const BATTERY_SERVICE_READY = 'BATTERY_SERVICE/READY';
export const BATTERY_SERVICE_ASSET_SELECTED = 'BATTERY_SERVICE/ASSET_SELECTED';
export const BATTERY_SERVICE_OPEN_REMOTE_DIALOG = 'BATTERY_SERVICE/OPEN_BATTERY_HISTORY_GRAPH_DIALOG';
export const OUT_NOTIFY_READINESS = 'MFE/BOOTSTRAP/MFE_IS_LOADED';

// Events received from web-workshop
export const TOKEN_RENEWED = 'WEB_WORKSHOP/TOKEN_RENEWED';
export const IN_MSG_FEATURE_TOGGLE_CHANGED = 'WEB_WORKSHOP/FEATURE_TOGGLE_CHANGED';

// Events received from customer-frontend
export const CFE_ASSET_TREE_SELECTION_CHANGED = 'CFE/ASSET_TREE_SELECTION_CHANGED';
export const CFE_DIAGNOSTICS_CRITICALITY_CHANGED = 'CFE/DIAGNOSTICS_CRITICALITY_CHANGED';
export const CFE_DIAGNOSTICS_VIEW_TYPE_CHANGED = 'CFE/DIAGNOSTICS_VIEW_TYPE_CHANGED';
export const CFE_DIAGNOSTICS_DETAIL_CLOSED = 'CFE/DIAGNOSTICS_DETAIL_CLOSED';
export const CFE_CURRENT_FILTERS_SELECTED = 'CFE/CURRENT_FILTERS_SELECTED';
export const IN_MSG_CFE_FEATURE_TOGGLE_CHANGED = 'CFE/FEATURE_TOGGLE_CHANGED';

interface MessageEvent<T> {
    type: string;
    payload: T;
}

export const sendMessage = (message: MessageEvent<any>) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const receiveMessage = (event: any, dispatchRemoteAction: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const { type, payload } = event.data;
    if (!type) {
        return;
    }

    switch (type) {
        case TOKEN_RENEWED:
            const { token } = event.data.payload;
            accessToken.saveAccessToken(token);
            break;
        case CFE_ASSET_TREE_SELECTION_CHANGED: {
            dispatchRemoteAction(assetsChanged(payload));
            return;
        }
        case CFE_DIAGNOSTICS_CRITICALITY_CHANGED: {
            dispatchRemoteAction(criticalityChanged(payload));
            return;
        }
        case CFE_DIAGNOSTICS_VIEW_TYPE_CHANGED: {
            dispatchRemoteAction(viewTypeChanged(payload));
            return;
        }
        case CFE_DIAGNOSTICS_DETAIL_CLOSED: {
            dispatchRemoteAction(detailClosed());
            return;
        }
        case CFE_CURRENT_FILTERS_SELECTED: {
            dispatchRemoteAction(currentFiltersSelected(payload));
            return;
        }
        case IN_MSG_FEATURE_TOGGLE_CHANGED:
        case IN_MSG_CFE_FEATURE_TOGGLE_CHANGED:
            dispatchRemoteAction(
                featureToggleChanged({
                    featureKey: payload.key,
                    value: payload.enabled,
                }),
            );
            break;
        default: {
            console.warn('MessageHandler not implemented: ', type);
        }
    }
};

// eslint-disable-next-line react/display-name
export const MessageHandler = React.memo((props) => {
    const dispatch = useAppDispatch();

    const isReadinessNotified = useAppSelector(isReadinessNotifiedSelector);
    useEffect(() => {
        const listenerCallback = (event: any) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);

        if (!isReadinessNotified) {
            sendMessage({
                type: OUT_NOTIFY_READINESS,
                payload: {
                    mfeName: 'battery-service',
                },
            });

            dispatch(readinessNotified());
        }

        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);

    return null;
});

export default MessageHandler;
