import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { FormattedMessage, useIntl } from 'react-intl';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import StatusCode from 'http-status-codes';

export type InternalErrorStateProps = {
    headlineI18n?: string;
    messageI18n?: string;
    errorCode: number;
    reloadTriggerFunction?: () => void;
    additionalClassName?: string;
    innerClassName?: string;
    fullWidth?: boolean;
    isCustomerFrontend?: boolean;
};

const InternalErrorState = (props: InternalErrorStateProps) => {
    const {
        errorCode,
        reloadTriggerFunction,
        additionalClassName,
        innerClassName,
        fullWidth,
        isCustomerFrontend = false,
    } = props;

    const intl = useIntl();

    if (errorCode === StatusCode.NOT_FOUND) {
        if (isCustomerFrontend) {
            return (
                <div>
                    <div className="margin-bottom-15">
                        <FormattedMessage id="batteryService.noDataAvailable" />
                    </div>
                </div>
            );
        } else {
            return (
                <NotFoundState message={intl.formatMessage({ id: 'batteryService.error.noInformationAvailable' })} />
            );
        }
    }

    return (
        <ErrorState
            icon={''}
            headline={intl.formatMessage({ id: 'batteryService.error.emptyState.headline' })}
            message={intl.formatMessage({ id: 'batteryService.error.emptyState.message' })}
            buttons={[
                {
                    text: intl.formatMessage({ id: 'batteryService.error.emptyState.button' }),
                    onClick: reloadTriggerFunction,
                },
            ]}
            // className should keep the original values plus the news values passed
            outerClassName={`display-flex justify-content-center max-width-100pct ${additionalClassName}`}
            innerClassName={innerClassName}
            fullWidth={fullWidth}
        />
    );
};

export default InternalErrorState;
