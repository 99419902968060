import { Suspense } from 'react';
import { LoadingIndicator } from '../features/app/LoadingIndicator';
import BatteryOverview from '../features/battery/workshop/BatteryOverview';
import BatterySidebar from '../features/battery/customer/BatterySidebar';
import { isWorkshopParentFrontend } from '../config';

const AssetBatteryData = () => {
    const isWorkshopParentFE = isWorkshopParentFrontend();

    return (
        <div className={'overflow-hidden display-flex flex-column'}>
            <Suspense fallback={<LoadingIndicator />}>
                {isWorkshopParentFE && <BatteryOverview />}
                {!isWorkshopParentFE && <BatterySidebar />}
            </Suspense>
        </div>
    );
};

export default AssetBatteryData;
