/* eslint-disable no-shadow */
import { FormattedMessage } from 'react-intl';
import LineChart from '@rio-cloud/rio-uikit/LineChart';
import Line from '@rio-cloud/rio-uikit/Line';
import Legend from '@rio-cloud/rio-uikit/Legend';
import ChartTooltip from '@rio-cloud/rio-uikit/ChartTooltip';
import { BatteryHistoryGraphTooltip } from './BatteryHistoryGraphTooltip';
import moment from 'moment';
import { LoadingScreen } from '../batteryOverview/utils/BatteryDataUtils';
import { useMatch } from 'react-router';
import { useFetchHistoricalBatteryHealthQuery } from '../battery/api/batterySlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import InternalErrorState from '../app/InternalErrorState';
import { isWorkshopParentFrontend } from '../../config';

export type BatteryHistoryGraphData =
    | {
          usage_days?: number;
          state_of_health_status: string | undefined;
          state_of_health_percentage: 0 | undefined | number;
          depth_of_discharge_percentage?: number;
          full_charge_capacity?: number;
          discharging_energy_throughput?: number;
          mileage?: number;
          timestamp: string;
      }[]
    | undefined;

const renderCfeHeader = () => {
    return (
        <>
            <div className={'margin-bottom-25'}>
                <span
                    className="text-color-black
                                   border border-color-black border-bottom-only border-width-3
                                   padding-x-25 padding-y-10
                                   text-size-14"
                >
                    <FormattedMessage id="batteryService.batteryAgingHistory.title" />
                </span>
            </div>
            <div className={'margin-bottom-15'}>
                <span>
                    <FormattedMessage id="batteryService.batteryAgingHistory.subTitle.extended" />
                </span>
            </div>
        </>
    );
};

const renderWfeHeader = () => {
    return (
        <>
            <h4 className="margin-top-15">
                <FormattedMessage id="batteryService.batteryAgingHistory.title" />
            </h4>
            <span className="margin-bottom-15">
                <FormattedMessage id="batteryService.batteryAgingHistory.subTitle" />
            </span>
        </>
    );
};

const BatteryHistoryGraph = () => {
    const isWorkshopParentFE = isWorkshopParentFrontend();

    const assetId = isWorkshopParentFE
        ? useMatch('/:assetId')?.params.assetId
        : useMatch('/dialog/:assetId')?.params.assetId;
    const {
        isLoading,
        isError,
        error,
        data: historicalBatteryHealth,
        refetch,
    } = useFetchHistoricalBatteryHealthQuery(assetId!!);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return (
            <InternalErrorState
                errorCode={(error as FetchBaseQueryError).status as number}
                reloadTriggerFunction={refetch}
            />
        );
    }

    const renderHeader = () => {
        return (
            <>
                {isWorkshopParentFE && renderWfeHeader()}
                {!isWorkshopParentFE && renderCfeHeader()}
            </>
        );
    };

    return (
        <>
            {renderHeader()}
            {historicalBatteryHealth && (
                <div className="height-400">
                    <LineChart
                        tooltip={<ChartTooltip content={<BatteryHistoryGraphTooltip />} />}
                        legend={
                            <Legend
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="left"
                                wrapperStyle={{
                                    marginLeft: 60,
                                }}
                                formatter={() => (
                                    <span className="text-color-black">
                                        <FormattedMessage id="batteryService.batteryAgingHistory.legend" />
                                    </span>
                                )}
                            />
                        }
                        margin={{ left: -20, top: 5, right: 5, bottom: 0 }}
                        data={historicalBatteryHealth}
                        dataKey={(entry: { timestamp: string }) => moment(entry.timestamp).format('D MMM')}
                        showYAxis
                        showGrid
                        dataUnit="%"
                        lines={[
                            <Line
                                key="line1"
                                dataKey="state_of_health_percentage"
                                dot={false}
                                activeDot={{ r: 5 }}
                                legendType="square"
                                strokeWidth={3}
                                strokeColor={isWorkshopParentFE ? 'gray-darkest' : 'brand-primary'}
                                type="monotone"
                            />,
                        ]}
                        xAxisOptions={{ interval: 'preserveStartEnd' }}
                        yAxisOptions={{ domain: [0, 100], allowDecimals: true, tickCount: 10 }}
                    />
                </div>
            )}
        </>
    );
};

export default BatteryHistoryGraph;
