import { createApi, fetchBaseQuery, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import {
    AssetAndBatteryOverview,
    ExternalBatteryOverviewListRequest,
    ExternalBatteryOverviewListResponse,
} from '../../../generated/GeneratedApiTypes';
import { prepareHeaders } from '../../../configuration/tokenHandling/prepareHeaders';
import { mapData } from '../utils/BatteryDataUtils';
import { DiagnosticsTableData, appIntl } from '../customer/DiagnosticsTable';

export const batteryOverviewApi = createApi({
    reducerPath: 'batteryOverviewApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BACKEND_URI}`, prepareHeaders }),
    refetchOnMountOrArgChange: 60,
    endpoints: (builder) => ({
        getBatteryOverview: builder.query<DiagnosticsTableData, ExternalBatteryOverviewListRequest>({
            query: (body: ExternalBatteryOverviewListRequest) => ({
                url: '/battery-overview',
                method: 'POST',
                body,
            }),
            transformResponse: (
                response: ExternalBatteryOverviewListResponse,
                meta: FetchBaseQueryMeta | undefined,
                arg: ExternalBatteryOverviewListRequest,
            ): DiagnosticsTableData => {
                const intl = appIntl;

                const assetsOverview = response.assets_data.map((singleBatteryOverview: AssetAndBatteryOverview) => {
                    return mapData(singleBatteryOverview, intl);
                });

                return {
                    assets: assetsOverview,
                    hasNext: response.has_next,
                };
            },
        }),
    }),
});

export const { useGetBatteryOverviewQuery } = batteryOverviewApi;
