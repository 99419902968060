export const isNumeric = (value: any): value is number => {
    return typeof value === 'number' || (typeof value === 'string' && value.length > 0 && !isNaN(+value));
};

type RoundIfNumericType = undefined | number;

export const roundIfNumeric = (numberToRound: RoundIfNumericType): RoundIfNumericType => {
    if (isNumeric(numberToRound)) {
        return Math.round(+numberToRound);
    }
    return numberToRound;
};
