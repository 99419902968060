import StatusBar from '@rio-cloud/rio-uikit/StatusBar';

// eslint-disable-next-line no-shadow
export enum Criticality {
    Critical = 'critical',
    Warning = 'warning',
    Normal = 'normal',
    Unknown = 'unknown',
}

type SidebarStatusBarProps = {
    percentage: number | undefined;
    criticality: Criticality;
};

type ProgressBarColor = 'warning' | 'danger' | '';

export const SidebarStatusBar = ({ percentage, criticality }: SidebarStatusBarProps) => {
    const TOTAL_PERCENTAGE = 100;
    const percentageOrZero = percentage ? percentage : 0;

    const renderProgressBarColor = (criticalLevel: Criticality): string => {
        switch (criticality) {
            case Criticality.Critical:
            case Criticality.Warning:
                return `progress-bar-${getStatusBarColor(criticalLevel)}`;
            case Criticality.Normal:
            default:
                return '';
        }
    };

    const getStatusBarColor = (criticalLevel: Criticality): ProgressBarColor => {
        switch (criticalLevel) {
            case Criticality.Critical:
                return 'danger';
            case Criticality.Warning:
                return 'warning';
            case Criticality.Normal:
            default:
                return '';
        }
    };

    if (criticality === Criticality.Unknown) {
        return <></>;
    }

    return (
        <StatusBar
            label={{
                value: `${percentageOrZero}%`,
                weight: 'light',
            }}
            progress={[
                {
                    percentage: percentageOrZero,
                    color: renderProgressBarColor(criticality),
                    tooltip: <span>{`${percentage}%`}</span>,
                },
                {
                    percentage: percentage ? TOTAL_PERCENTAGE - percentage : 0,
                    color: 'progress-bar-light',
                    tooltip: <span>{`${percentage && TOTAL_PERCENTAGE - percentage}%`}</span>,
                },
            ]}
            useProgressDivider={false}
        />
    );
};
