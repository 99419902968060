import { combineReducers, configureStore } from '@reduxjs/toolkit';
import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../features/app/appSlice';
import batteryOverviewReducer from '../../features/batteryOverview/slices/batteryOverviewSlice';
import { batteryApi } from '../../features/battery/api/batterySlice';
import { batteryOverviewApi } from '../../features/batteryOverview/api/batteryOverviewSlice';
import featureToggleReducer, { FEATURE_SLICE_NAME } from '../featureToggle/featureToggleSlice';

const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
    batteryOverview: batteryOverviewReducer,
    [batteryApi.reducerPath]: batteryApi.reducer,
    [batteryOverviewApi.reducerPath]: batteryOverviewApi.reducer,
    [FEATURE_SLICE_NAME]: featureToggleReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(batteryApi.middleware, batteryOverviewApi.middleware),
    });
};

export const store = setupStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type RootDispatch = AppStore['dispatch'];
