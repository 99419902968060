import { IntlShape } from 'react-intl/lib';

export const getDaysInYearsAndMonths = (value: number | undefined, intl: IntlShape) => {
    const SPACE = ' ';
    const yearsString = SPACE + intl.formatMessage({ id: 'batteryService.years' });
    const yearString = SPACE + intl.formatMessage({ id: 'batteryService.year' });
    const monthsString = SPACE + intl.formatMessage({ id: 'batteryService.months' });
    const monthString = SPACE + intl.formatMessage({ id: 'batteryService.month' });
    const daysString = SPACE + intl.formatMessage({ id: 'batteryService.days' });
    const dayString = SPACE + intl.formatMessage({ id: 'batteryService.day' });

    if (value) {
        const years = Math.floor(value / 365);
        const months = Math.floor((value % 365) / 30);

        if (value < 31) {
            return getDaysString(value, daysString, dayString);
        }

        if (value < 365) {
            return getMonthsString(months, monthsString, monthString);
        }

        if (months > 0) {
            return (
                getYearsString(years, yearsString, yearString) +
                SPACE +
                getMonthsString(months, monthsString, monthString)
            );
        } else {
            return getYearsString(years, yearsString, yearString);
        }
    }

    return '';
};

const getDaysString = (value: number, daysString: string, dayString: string) => {
    if (value === 1) {
        return value + dayString;
    } else {
        return value + daysString;
    }
};

const getMonthsString = (value: number, monthsString: string, monthString: string) => {
    if (value === 1) {
        return value + monthString;
    } else {
        return value + monthsString;
    }
};

const getYearsString = (value: number, yearsString: string, yearString: string) => {
    if (value === 1) {
        return value + yearString;
    } else {
        return value + yearsString;
    }
};
