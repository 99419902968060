import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../../configuration/tokenHandling/prepareHeaders';
import {
    BatteryHealthMonitoring,
    ExternalActivationState,
    ExternalHistoricalBatteryHealthResponse,
    SystemLevelBatteryStatuses,
} from '../../../generated/GeneratedApiTypes';
import { roundIfNumeric } from '../utils/NumberUtils';
import { handleVinSearchToken } from '../workshop/BatteryOverview';

const roundFields = (data: BatteryHealthMonitoring) => {
    const newData = { ...data };
    if (data.health_data !== undefined) {
        newData.health_data!!.system.discharging_energy_throughput = roundIfNumeric(
            data.health_data.system.discharging_energy_throughput,
        );
        newData.health_data!!.system.state_of_health_percentage = roundIfNumeric(
            data.health_data.system.state_of_health_percentage,
        );
        newData.health_data!!.system.mileage = roundIfNumeric(data.health_data.system.mileage);
        newData.health_data!!.system.full_charge_capacity = roundIfNumeric(
            data.health_data.system.full_charge_capacity,
        );
    }
    return newData;
};

export const formatGraphData = (data: ExternalHistoricalBatteryHealthResponse): SystemLevelBatteryStatuses[] => {
    const newData = { ...data };
    if (newData.battery_health_records !== undefined) {
        return newData.battery_health_records.map((historicalData) => {
            return {
                ...historicalData,
                depth_of_discharge_percentage: roundIfNumeric(historicalData.depth_of_discharge_percentage),
                discharging_energy_throughput: roundIfNumeric(historicalData.discharging_energy_throughput),
                full_charge_capacity: roundIfNumeric(historicalData.full_charge_capacity),
                state_of_health_percentage: roundIfNumeric(historicalData.state_of_health_percentage),
                state_of_health_status: historicalData.state_of_health_status?.toLowerCase(),
            };
        });
    }
    return [];
};

export const batteryApi = createApi({
    reducerPath: 'batteryApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BACKEND_URI}`, prepareHeaders }),
    endpoints: (builder) => ({
        fetchBatteryLevels: builder.query<BatteryHealthMonitoring, string>({
            query: (assetId) => ({
                url: `/battery-overview/${assetId}`,
                headers: { 'X-auth': handleVinSearchToken(assetId) },
            }),
            transformResponse: roundFields,
        }),
        fetchHistoricalBatteryHealth: builder.query<SystemLevelBatteryStatuses[], string>({
            query: (assetId) => ({
                url: `/battery-historical-health/${assetId}`,
                headers: { 'X-auth': handleVinSearchToken(assetId) },
            }),
            transformResponse: formatGraphData,
        }),
        fetchProductForAsset: builder.query<ExternalActivationState, string>({
            query: (assetId) => ({
                url: `activation-state/${assetId}`,
                headers: { 'X-auth': handleVinSearchToken(assetId) },
            }),
        }),
    }),
});

export const { useFetchBatteryLevelsQuery, useFetchHistoricalBatteryHealthQuery, useFetchProductForAssetQuery } =
    batteryApi;
